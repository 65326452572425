<!-- 工时管理 > 工时统计 > 项目工时统计(组件) -->
<template>
  <div class="fullScreen show">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        <span class="close_btn" @click="handleStatisticsClose">
          工时统计<i class="el-icon-close"></i>
        </span>
      </el-breadcrumb-item>
    </Crumbs>
    <div class="mainList">
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <div class="mainList_content_sub">
            <el-table
              v-loading="loading"
              ref="multipleTable"
              style="width: 100%"
              border
              :data="tableData"
              :header-cell-style="{
                background: '#F4F4F4',
                textAlign: 'center',
              }">
              <el-table-column
                prop="userName"
                label="填报人"
                align="center"
                width="130"
                show-overflow-tooltip></el-table-column>
              <el-table-column
                prop="departmentName"
                label="所属部门"
                align="center"
                width="150"
                show-overflow-tooltip></el-table-column>
              <el-table-column
                prop="projectName"
                label="项目名称"
                align="center"
                show-overflow-tooltip></el-table-column>
              <!-- <el-table-column prop="projectCode" label="项目编号" align="center" width="180" show-overflow-tooltip></el-table-column> -->
              <el-table-column
                prop="sumHour"
                label="工时总数"
                align="center"
                width="120"></el-table-column>
              <el-table-column
                prop="submitCount"
                label="填报次数"
                align="center"
                width="120"></el-table-column>
              <el-table-column fixed="right" align="center" width="140">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    class="text_Details_Bgc"
                    @click="handleDetailed(scope.row)"
                    >工时明细</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <!-- 工时明细(弹出框) -->
    <statistics-detailed-dlg
      v-if="isDetailedDlg"
      :isDetailedDlg="isDetailedDlg"
      :getInfoData="infoData"
      @childEvent="eventDetailed">
    </statistics-detailed-dlg>
    <!-- 工时明细(弹出框) -->
  </div>
</template>

<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    StatisticsDetailedDlg: () => import('./StatisticsDetailedDlg'),
  },
  props: {
    isStatisticsDlg: {
      type: Boolean,
      default: false,
    },
    getProjectId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false, // 表格 -- 加载 变量
      projectData: [], // 项目名称 下拉列表数据
      tableData: [], // 表格 列表数据
      isDetailedDlg: false, // 是否 "显示" 或 "隐藏" 工时明细(弹出框)
      infoData: {}, // 列表 数据
    }
  },
  mounted() {
    /** 加载 "项目工时统计" 列表数据 **/
    this.getHourStatisticsListData()
  },
  methods: {
    /** 加载 "项目工时统计" 列表数据 **/
    getHourStatisticsListData() {
      this.loading = true
      let _pamse = {
        projectId: this.getProjectId,
      }

      this.$api.hour
        .hourStatistics(_pamse)
        .then(res => {
          this.tableData = res.data
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /**
     * 点击 "列表 -- 工时明细" 操作
     * @param  item     列表数据
     */
    handleDetailed(item) {
      this.isDetailedDlg = true
      this.infoData = item
    },

    /** 关闭 “项目工时统计” 组件 **/
    handleStatisticsClose() {
      this.$emit('childEvent', false)
    },

    /**
     * 调用 “项目工时明细” 组件
     * @param  data    子组件 传过来的值
     */
    eventDetailed(data) {
      this.isDetailedDlg = data
    },
  },
}
</script>

<style lang="scss" scoped>
.close_btn {
  position: absolute;
  top: 10px;
  display: inline-block;
  color: #ffffff;
  background-color: #014d67;
  padding: 5px 12px 5px 16px;
  margin-left: 12px;
  border-radius: 4px;
  box-sizing: border-box;
}

.close_btn:hover {
  cursor: pointer;
}
</style>
